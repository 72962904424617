/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reset_password_hover': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#154f4a" fill-rule="nonzero"><path pid="0" d="M1.042 13.171a.5.5 0 01.943-.333 8.503 8.503 0 0016.026.012c.322-.907.489-1.867.489-2.85a.5.5 0 111 0 9.485 9.485 0 01-.547 3.185 9.503 9.503 0 01-17.91-.014zm16.584-6.93a8.504 8.504 0 00-15.656.963C1.66 8.095 1.5 9.036 1.5 10a.5.5 0 11-1 0c0-1.076.18-2.128.526-3.124a9.503 9.503 0 0117.501-1.07l.269-.736a.5.5 0 11.94.342l-.6 1.644a.75.75 0 01-.96.448l-1.645-.598a.5.5 0 01.342-.94l.753.274zm-14.5 7.837l-1.4-.537-.537 1.4a.5.5 0 01-.934-.358l.628-1.634a.75.75 0 01.969-.431l1.633.627a.5.5 0 11-.358.933z"/><path pid="1" d="M7.722 8.48a.5.5 0 11-1 0V6.649a3.295 3.295 0 116.59 0 .5.5 0 01-1 0 2.295 2.295 0 00-4.59 0v1.83z"/><path pid="2" d="M7 9a1 1 0 00-1 1v3a1 1 0 001 1h6a1 1 0 001-1v-3a1 1 0 00-1-1H7zm0-1h6a2 2 0 012 2v3a2 2 0 01-2 2H7a2 2 0 01-2-2v-3a2 2 0 012-2z"/></g>'
  }
})
